<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Партнеры</div>
        <div class="tools">
          <router-link class="btn btn-accent" to="/partner/create"
            >Добавить партнера</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <partners-table />
      </div>
    </div>
  </div>
</template>

<script>
import PartnersTable from "../../feature/partners/tables/PartnersTable.vue";
import useCheckRights from "../../mixins/useCheckRights";
export default {
  components: { PartnersTable },
  name: "partners-page",
  setup() {
    const { getRight } = useCheckRights();

    return {
      getRight,
    };
  },
};
</script>

<style>
</style>